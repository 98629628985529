import { ANALYTICS_EVENTS, CMS_BLOCK_THEME_COLOR, colors, DECAL_TYPE } from '@nandosaus/constants';
import { isEmpty, pick } from 'lodash';
import PropTypes from 'prop-types';

import { useBrazeImpression } from '@hooks/use-braze-impression';
import { analytics } from '../../../analytics';
import { assetQuery, buttonQuery, matrixBlockQuery } from '../../../content/query-snippets';
import { BottomDiagonalDecal } from '../../bottom-diagonal-decal';
import { Box } from '../../grid';
import { Button } from '../../button';
import { buttonTransform, imageTransform } from '../../../content/transforms';
import { contentImageDefaultProps, contentImagePropTypes } from '../prop-types';
import { DiagonalVertical } from '../../diagonal-vertical';
import { HeadingWithLineHighlights } from '../../heading-with-line-highlights';
import { Image } from '../../image';
import { P, RichText } from '../../typography';

const SUPPORTED_THEME_COLORS = pick(CMS_BLOCK_THEME_COLOR, ['GREEN', 'BLUE', 'PINK']);
const { PINK, GREEN, BLUE } = SUPPORTED_THEME_COLORS;

const THEME_VALUES = {
  [PINK]: {
    bottomDiagonalTheme: {
      bottomIconColor: colors.yellow500,
      diagonalColor: colors.pink400,
      topIconColor: colors.pink700,
    },
    backgroundColor: colors.pink50,
    decalType: DECAL_TYPE.HEART,
    highlightBackgroundColor: colors.pink300,
  },

  [GREEN]: {
    bottomDiagonalTheme: {
      bottomIconColor: colors.yellow500,
      diagonalColor: colors.neonTeal800,
      topIconColor: colors.green500,
    },
    backgroundColor: colors.green100,
    decalType: DECAL_TYPE.PEPPER,
    highlightBackgroundColor: colors.neonTeal400,
  },
  [BLUE]: {
    bottomDiagonalTheme: {
      bottomIconColor: colors.blue100,
      diagonalColor: colors.blue500,
      topIconColor: colors.neonTeal400,
    },
    backgroundColor: colors.blue200,
    decalType: DECAL_TYPE.FLAME,
    highlightBackgroundColor: colors.blue500,
  },
};

const getBrazeButtonProps = ({ brazeLogClick, buttonLink, text }) => {
  if (!brazeLogClick || !buttonLink) return undefined;

  return {
    text,
    href: buttonLink,
  };
};

const HeroButton = ({ button, buttonLink, brazeLogClick }) => {
  const transformedButton = buttonTransform(button);
  const brazeButton = getBrazeButtonProps({
    buttonLink,
    brazeLogClick,
    text: transformedButton?.text || button,
  });
  const buttonProps = brazeButton || transformedButton;

  const onClick = () => {
    analytics.track(ANALYTICS_EVENTS.PAGE_HEADER_BLOCK_CLICKED, {
      text: buttonProps.text,
    });
    brazeLogClick?.();
  };

  if (isEmpty(buttonProps)) {
    return null;
  }

  return (
    <Button mb={{ xs: '4rem', lg: '4rem' }} mt="2rem" onClick={onClick} {...buttonProps}>
      {buttonProps.text}
    </Button>
  );
};

HeroButton.propTypes = {
  button: PropTypes.object,
  buttonLink: PropTypes.string,
  brazeLogClick: PropTypes.func,
};

HeroButton.defaultProps = {
  button: null,
  buttonLink: null,
  brazeLogClick: null,
};

const HeroBlock = ({
  body,
  button,
  heading,
  image,
  overrideHeadingHighlighting,
  themeColor,
  brazeLogClick,
  buttonLink,
  brazeLogImpression,
}) => {
  useBrazeImpression({ brazeLogImpression });

  const transformedImage = imageTransform(image);

  const { bottomDiagonalTheme, backgroundColor, decalType, highlightBackgroundColor } =
    THEME_VALUES[themeColor] || THEME_VALUES[GREEN];

  return (
    <Box display="grid" gridTemplateColumns={{ lg: '4fr 4fr' }} mx="auto" mb={2}>
      <Box position="relative" bg={backgroundColor}>
        <Image
          src="/static/images/hero-block/background-texture.png"
          alt=""
          position="absolute"
          width="100%"
          height="100%"
        />
        <DiagonalVertical
          right="0"
          position="absolute"
          width="100px"
          zIndex="2"
          transform="translateX(50%)"
          color={backgroundColor}
          display={{ _: 'none', lg: 'flex' }}
        />
        <Box position="absolute" bottom="0" left="0" width="100%" zIndex="2" overflow="hidden">
          <BottomDiagonalDecal decalType={decalType} colorTheme={bottomDiagonalTheme} width="100%" height="auto" />
        </Box>
        <Box
          display="flex"
          margin="auto"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          height="100%"
          position="relative"
          zIndex="2"
          pt="2rem"
          pb={{ _: '60px', mg: '80px', lg: '80px' }}
        >
          {!!heading && (
            <Box px={2}>
              <HeadingWithLineHighlights
                {...{
                  heading,
                  highlightBackgroundColor,
                  overrideHeadingHighlighting,
                }}
              />
            </Box>
          )}

          <Box width={6 / 12}>
            {!!body && (
              <P textAlign="center" variant={3} mt={1}>
                <RichText keepMargins>{body}</RichText>
              </P>
            )}
            <HeroButton buttonLink={buttonLink} button={button} brazeLogClick={brazeLogClick} />
          </Box>
        </Box>
      </Box>

      {image ? (
        <Box zIndex="1">
          <Image
            alt={transformedImage.alt}
            focalPoint={transformedImage.focalPoint}
            src={transformedImage.url}
            srcSet={transformedImage.srcset}
            height="100%"
            width="100%"
            objectFit="cover"
          />
        </Box>
      ) : null}
    </Box>
  );
};

HeroBlock.propTypes = contentImagePropTypes;

HeroBlock.defaultProps = contentImageDefaultProps;

HeroBlock.query = `
  ... on contentMatrix_heroBlock_BlockType {
    ${matrixBlockQuery}
    heading
    body
    ${buttonQuery}
    heading
    ${assetQuery('image')}
    overrideHeadingHighlighting
    themeColor
    brazeId
  }
`;

export { HeroBlock, SUPPORTED_THEME_COLORS };
